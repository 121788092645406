import { ApiError, request } from '@counsel-project/client-utils'
import { url } from '../api/url'

export const hubspotIdentify = async (): Promise<{ token: string }> => {
  const response = await request({
    method: 'POST',
    url: url + '/hubspot/login',
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Failed to identify user with HubSpot')
  }

  return response.body
}
