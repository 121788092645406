import { capitalize } from '@counsel-project/client-utils'
import { RSiteBanner } from '@counsel-project/counsel-auth-api/dist/common/database/SiteBanner'
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import LoginIcon from '@mui/icons-material/LoginRounded'
import MenuIcon from '@mui/icons-material/MenuRounded'
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded'
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import SpaceDashboardRoundedIcon from '@mui/icons-material/SpaceDashboardRounded'
import TranscribeIcon from '@mui/icons-material/TranscribeRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Outlet, useNavigate } from 'react-router-dom'
import AccountButton from '../components/AccountButton'
import Footer from '../components/Footer'
import NavListButton from '../components/NavListButton'
import { usePatientNomenclature } from '../util'
import { authRequest } from '../util/api/auth-api'
import checkToken from '../util/auth/checkToken'
import useUser from '../util/auth/useUser'
import { useTracking } from '../util/tracking'
import InvitationsDialog from './invitations/InvitationsDialog'
import Tutorial from '../components/Tutorial'
import HubSpotChat from './HubspotChat'

const FixedToolbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
}))

const NavLayout = () => {
  useTracking()

  const isLoggedIn = Cookies.get('expiresAt') !== undefined

  // If webView query parameter is present, set webView
  const webView = useMemo(() => window.location.search.includes('webView=true'), [])

  const navButtonEl = useRef<HTMLButtonElement>(null)
  const [navOpen, setNavOpen] = useState(false)
  const navigate = useNavigate()
  const [user] = useUser()
  const [banners, setBanners] = useState<RSiteBanner[]>([])

  const handleClickNavButton = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (navButtonEl.current) {
      setNavOpen(true)
    }
  }, [])

  const handleCloseNavMenu = () => {
    setNavOpen(false)
  }

  const createHeaderNavHandler = useCallback(
    (path: string) => (e: React.MouseEvent) => {
      e.preventDefault()
      navigate(path)
      setNavOpen(false)
    },
    [navigate]
  )

  const patientNomenclature = usePatientNomenclature()

  const sections: {
    id: string
    title: string
    icon: React.ReactNode
    href: string
    action?: (e: React.MouseEvent) => void
    color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'
    auth?: boolean
    tag?: string
  }[] = [
    {
      id: 'nav-documents',
      title: 'Documentation',
      icon: <TranscribeIcon />,
      href: '/builder',
      color: 'primary',
      auth: true,
      action: createHeaderNavHandler('/builder'),
    },
    {
      id: 'nav-sessions',
      title: `${capitalize(patientNomenclature)}s`,
      icon: <RecentActorsIcon />,
      href: '/sessions',
      color: 'primary',
      auth: true,
      action: createHeaderNavHandler('/sessions'),
    },
    {
      id: 'nav-custom',
      title: 'Templates',
      icon: <SpaceDashboardRoundedIcon />,
      href: '/layouts',
      color: 'primary',
      auth: true,
      action: createHeaderNavHandler('/layouts'),
    },
    {
      id: 'nav-login',
      title: 'Login',
      icon: <LoginIcon />,
      href: '/login',
      color: 'primary',
      auth: false,
      action: createHeaderNavHandler('/login'),
    },
    {
      id: 'nav-create-account',
      title: 'Create Account',
      icon: <PersonAddAlt1RoundedIcon />,
      href: '/pricing-select',
      color: 'primary',
      auth: false,
      action: createHeaderNavHandler('/pricing-select'),
    },
    {
      id: 'nav-help',
      title: 'Help',
      icon: <QuestionMarkRoundedIcon />,
      href: '/help',
      action: createHeaderNavHandler('/help'),
      color: 'primary',
    },
    {
      id: 'nav-pricing',
      title: 'Pricing',
      icon: <CategoryRoundedIcon />,
      href: '/pricing',
      action: createHeaderNavHandler('/pricing'),
      color: 'secondary',
    },
    {
      id: 'nav-referral',
      title: 'Referral',
      icon: <AttachMoneyRoundedIcon />,
      href: '/referral',
      auth: true,
      action: createHeaderNavHandler('/referral'),
      color: 'secondary',
    },
  ]

  const populateBanners = useCallback(async () => {
    try {
      const loggedIn = await checkToken()
      if (!loggedIn) return

      const { results } = await authRequest.user.banners.list({ token: '' })
      if (results.length === 0) return

      setBanners(results)
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    if (!user) return
    populateBanners()
  }, [user, populateBanners])

  return (
    <>
      {banners.map((banner) => (
        <Box
          key={banner._id}
          sx={{
            p: 0.5,
            px: 1,
            width: '100%',
            backgroundColor: 'secondary.main',
          }}
        >
          <Typography variant="body1" color="secondary.contrastText" fontWeight={500}>
            {banner.text}
          </Typography>
        </Box>
      ))}
      {!webView && (
        <FixedToolbar component="nav" display="flex" justifyContent="start" id="main-toolbar">
          <Box
            component="a"
            href="/"
            id="logo-home"
            sx={{
              mx: 2,
              display: { md: 'flex', xs: 'none' },
              textDecoration: 'none',
              color: 'inherit',
              alignItems: 'center',
            }}
            onClick={createHeaderNavHandler('/')}
          >
            <img src="/logo-alt.svg" alt="Logo" width={40} height={40} />
            <Typography
              variant="subtitle1"
              fontWeight={600}
              component="h1"
              sx={{ ml: 2 }}
              fontFamily="Comfortaa, cursive"
              lineHeight={1.1}
            >
              Clinical Notes AI
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { md: 'block', xs: 'none' } }}>
            {sections.map((section, i) => {
              if (section.auth && !isLoggedIn) return null
              if (section.auth === false && isLoggedIn) return null
              return (
                <NavListButton
                  key={i}
                  id={section.id}
                  color={section.color}
                  title={section.title}
                  icon={section.icon}
                  href={section.href}
                  onClick={section.action}
                  tag={section.tag}
                />
              )
            })}
          </Box>
          <Box sx={{ flexGrow: 1, display: { md: 'none', xs: 'flex' } }}>
            <IconButton
              aria-controls="mobile-nav-menu"
              aria-label="open menu"
              aria-expanded={navOpen}
              id="mobile-nav-menu-button"
              color="primary"
              onClick={handleClickNavButton}
              ref={navButtonEl}
              sx={{ ml: 2 }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              id="logo-home-mobile"
              sx={{
                mx: 2,
                display: { md: 'none', xs: 'flex' },
                alignItems: 'center',
              }}
            >
              <img src="/logo-alt.svg" alt="Logo" width={40} height={40} />
              <Typography
                variant="subtitle2"
                component="h1"
                fontWeight={600}
                lineHeight={'1.2em'}
                sx={{
                  ml: 1,
                  display: !isLoggedIn ? { xs: 'none', sm: 'inline-block' } : 'inline-block',
                }}
                fontFamily="Comfortaa, cursive"
              >
                Clinical Notes AI
              </Typography>
            </Box>
          </Box>
          {!!isLoggedIn && <AccountButton />}
          {!isLoggedIn && (
            <Button
              id="nav-schedule-demo"
              onClick={() => {
                window.open('https://calendly.com/clinicalnotesai/30min', '_blank')
              }}
              variant="contained"
              color="secondary"
              sx={{ mr: 2 }}
              disableElevation
            >
              Schedule a Demo
            </Button>
          )}
          <Menu
            aria-expanded={navOpen}
            id="mobile-nav-menu"
            anchorEl={navButtonEl.current}
            open={navOpen}
            onClose={handleCloseNavMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={(theme) => ({
              '& .MuiMenu-paper': {
                backgroundColor: theme.palette.background.default,
                boxShadow: theme.shadows[24],
              },
            })}
          >
            <MenuItem
              id="mobile-nav-home"
              LinkComponent={'a'}
              href="/"
              onClick={createHeaderNavHandler('/')}
            >
              <HomeRoundedIcon sx={{ mr: 1 }} />
              Home
            </MenuItem>
            {sections.map((section, i) => {
              if (section.auth && !isLoggedIn) return null
              if (section.auth === false && isLoggedIn) return null
              return (
                <MenuItem
                  key={i}
                  href={section.href}
                  LinkComponent={'a'}
                  onClick={section.action}
                  id={'mobile-' + section.id}
                >
                  <Box
                    sx={(theme) => ({
                      mr: 1,
                      // color: section.color ? theme.palette[section.color]?.main : undefined,
                      display: 'flex',
                      alignItems: 'center',
                    })}
                  >
                    {section.icon}
                  </Box>
                  <Typography>{section.title}</Typography>
                </MenuItem>
              )
            })}
          </Menu>
        </FixedToolbar>
      )}
      <Outlet />
      <Container sx={{ p: 2, mt: 2 }} maxWidth="lg">
        <Footer />
      </Container>
      <Toaster
        gutter={8}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            borderRadius: 12,
          },
        }}
      />
      <Tutorial />
      <InvitationsDialog />
      <HubSpotChat />
    </>
  )
}

export default NavLayout
