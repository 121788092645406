import { RLayout, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import CheckIcon from '@mui/icons-material/CheckRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import ErrorIcon from '@mui/icons-material/ErrorRounded'
import RetryIcon from '@mui/icons-material/ReplayRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import Breadcrumbs from '../../components/Breadcrumbs'
import PageContainer from '../../components/layout/PageContainer'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import useLayouts from '../../util/auth/useLayouts'
import log from '../../util/logging'
import { createSearchParams } from './common'

type CenterProps = {
  children: React.ReactNode
}

const Center = ({ children }: CenterProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{children}</Box>
)

const OutlinedBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}))

type SessionGeneratingCardProps = {
  session: RPatientSession
  layouts: RLayout[]
  loading?: boolean
  onClickRetry?: () => void
  onClickView?: () => void
  onClickCancel?: () => void
}

const SessionGeneratingCard = ({
  session,
  layouts,
  loading,
  onClickRetry,
  onClickView,
  onClickCancel,
}: SessionGeneratingCardProps) => {
  const foundLayout = useMemo(
    () => layouts.find((l) => l.identifier === session.layout),
    [session, layouts]
  )

  const title = useMemo(() => {
    if (foundLayout?.config.multiplePeople) {
      return session.patientLabel || foundLayout?.name
    }
    return foundLayout?.name
  }, [foundLayout, session])

  return (
    <OutlinedBox>
      <Grid container alignItems="center" spacing={1} padding={2}>
        <Grid item xs>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {session.startedAtString}
          </Typography>
          {session.summary && (
            <Typography variant="body2" color="text.secondary">
              {session.summary}
            </Typography>
          )}
          {session.state === 'errored' && (
            <Typography variant="body2" color="error">
              {session.error || 'An unexpected error has occurred'}
            </Typography>
          )}
        </Grid>
        {session.state === 'generating' && (
          <Grid item>
            <Center>
              <CircularProgress size={18} color="secondary" className="generating-progress" />
            </Center>
          </Grid>
        )}
        {session.state === 'canceled' && (
          <Grid item>
            <Center>
              <CloseIcon color="error" className="generating-cancel" />
            </Center>
          </Grid>
        )}
        {session.state === 'errored' && (
          <Grid item>
            <Center>
              <ErrorIcon color="error" className="generating-error" />
            </Center>
          </Grid>
        )}
        {session.state === 'generated' && (
          <Grid item>
            <Center>
              <CheckIcon color="primary" className="generating-success" />
            </Center>
          </Grid>
        )}
        {session.state === 'generating' && (
          <Grid item>
            <Center>
              <Button onClick={onClickCancel} color="secondary" disabled={loading}>
                Cancel
              </Button>
            </Center>
          </Grid>
        )}
        {session.state === 'generated' && (
          <Grid item>
            <Center>
              <Button onClick={onClickView} disabled={loading} className="view-note-button">
                View
              </Button>
            </Center>
          </Grid>
        )}
        {session.state === 'errored' && (
          <Grid item>
            <Center>
              <IconButton onClick={onClickRetry} disabled={loading}>
                <RetryIcon />
              </IconButton>
            </Center>
          </Grid>
        )}
      </Grid>
    </OutlinedBox>
  )
}

const BuilderGeneratingPage = () => {
  useTitle('Clinical Notes AI - Generating')

  const [searchParams] = useSearchParams()

  const sessionId = searchParams.get('sessionId') || ''
  const patientLabel = searchParams.get('patientLabel') || ''
  const shownSessionIds = searchParams.get('shownSessionIds') || ''
  const shownSessionIdsMap = useMemo(() => {
    return shownSessionIds.split(',').filter(Boolean)
  }, [shownSessionIds])

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [sessions, setSessions] = useState<RPatientSession[]>([])
  const [total, setTotal] = useState(0)
  const [stopCheckState, setStopCheckState] = useState(false)

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const checkState = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { results, total } = await transcribeRequest.sessions.list.all({
        token: '',
        search: {
          ...(shownSessionIdsMap.length !== 0
            ? { or: [...shownSessionIdsMap.map((id) => ({ _id: id }))] }
            : { and: [{ patientLabel }] }),
        },
        limit: 40,
        sort: 'createdAt',
        direction: 'desc',
      })

      setTotal(total)
      setSessions(results)

      if (results.some((r) => r.state === 'generating')) return
      setStopCheckState(true)
    } catch (err) {
      log.error(err)
      toast.error('An error occurred')
    } finally {
      setLoading(false)
    }
  }, [shownSessionIdsMap, patientLabel])

  useEffect(() => {
    if (stopCheckState) return
    const timeout = setTimeout(() => {
      checkState()
    }, 100)
    const interval = setInterval(() => {
      checkState()
    }, 8000)
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [checkState, stopCheckState])

  const handleNavAllDocumentation = () => {
    navigate('/sessions?listType=all')
  }

  const handleNavGenerateAnother = () => {
    const queryText = createSearchParams({
      sessionId,
      shownSessionIds,
    })

    navigate(`/builder${queryText}`)
  }

  const retrySessionHandler = (session: RPatientSession) => () => {
    const queryText = createSearchParams({
      sessionId,
      shownSessionIds,
    })

    navigate(`/builder/regenerate/${session._id}${queryText}`)
  }

  const viewSessionHandler = (session: RPatientSession) => () => {
    const queryText = createSearchParams({
      ref: `/builder/generating${window.location.search}`,
    })

    navigate(`/sessions/${session._id}${queryText}`)
  }

  const cancelSessionHandler = (session: RPatientSession) => async () => {
    try {
      setLoading(true)

      await checkToken()

      await transcribeRequest.sessions.generate.cancel({
        token: '',
        sessionId: session._id,
      })

      checkState()
    } catch (err) {
      log.error(err)
      toast.error('An error occurred')
    } finally {
      setLoading(false)
    }
  }

  const handleNavViewAll = () => {
    const queryText = createSearchParams({
      ref: `/builder/generating${window.location.search}`,
      sessionIds: sessions.map((s) => s._id).join(','),
      sessionId,
    })

    navigate(`/sessions/multiple${queryText}`)
  }

  const getBuilderBackPath = useCallback(() => {
    const queryText = createSearchParams({
      sessionId,
      shownSessionIds,
    })

    return `/builder${queryText}`
  }, [sessionId, shownSessionIds])

  const breadcrumbs = [{ name: 'Builder', path: getBuilderBackPath() }, { name: 'Generating' }]

  return (
    <PageContainer>
      <Breadcrumbs paths={breadcrumbs} />
      <Grid container spacing={2}>
        {total > 1 && sessions.every((s) => s.state === 'generated') && (
          <Grid item xs={12}>
            <OutlinedBox>
              <Grid container alignItems="center" spacing={1} padding={2}>
                <Grid item xs>
                  <Typography variant="h6">View All</Typography>
                  <Typography variant="body2" color="text.secondary">
                    View all these documents in one page
                  </Typography>
                </Grid>

                <Grid item>
                  <Center>
                    <Button onClick={handleNavViewAll} disabled={loading}>
                      View
                    </Button>
                  </Center>
                </Grid>
              </Grid>
            </OutlinedBox>
          </Grid>
        )}
        {sessions.map((session) => (
          <Grid item key={session._id} xs={12}>
            <SessionGeneratingCard
              session={session}
              layouts={layouts}
              onClickRetry={retrySessionHandler(session)}
              onClickView={viewSessionHandler(session)}
              onClickCancel={cancelSessionHandler(session)}
              loading={loading}
            />
          </Grid>
        ))}
        {total !== 0 && (
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">
              {total} In Total
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} paddingTop={2}>
        <Grid item>
          {sessions.some((s) => s.state === 'generating') ? (
            <Typography variant="h6" gutterBottom>
              Generating documentation... This may take a few minutes.
            </Typography>
          ) : (
            <Typography variant="h6" gutterBottom>
              No documentation is currently generating
            </Typography>
          )}
          {sessions.some((s) => s.state === 'generating') && (
            <Typography variant="body1">You may exit and return to this page later</Typography>
          )}
          <Grid container spacing={2} paddingTop={2}>
            <Grid item xs={12} sm="auto">
              <Button onClick={handleNavAllDocumentation} fullWidth>
                View My Documentation
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button onClick={handleNavGenerateAnother} fullWidth>
                {sessionId
                  ? 'Generate Additional Documentation For This Session'
                  : 'Generate More Documentation'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default BuilderGeneratingPage
