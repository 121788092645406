import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import useUser from '../util/auth/useUser'
import { useLocation } from 'react-router-dom'
import { RUser } from '@counsel-project/counsel-auth-api'
import { hubspotIdentify } from '../util/auth/hubspotIdentify'
import log from '../util/logging'

interface GlobalWindow {
  hsConversationsSettings: {
    loadImmediately: boolean
    identificationEmail: string
    identificationToken: string
  }
  HubSpotConversations: {
    widget: {
      refresh: () => void
      load: () => void
      remove: () => void
      close: () => void
    }
  }
}

declare global {
  interface Window extends GlobalWindow {}
}

const HubSpotChat = () => {
  const [user] = useUser()
  const location = useLocation()

  const widgetLoading = useRef(false)
  const widgetLoaded = useRef(false)

  const removeWidget = useCallback(() => {
    // Remove the script
    const existingScript = document.getElementById('hs-script-loader')
    if (existingScript) {
      document.body.removeChild(existingScript)
    }

    // Clean up HubSpot elements
    const hubspotElements = document.querySelectorAll('[id^="hubspot"]')
    hubspotElements.forEach((element) => {
      element.remove()
    })

    // Clear any existing HubSpot cookies/storage
    if ('HubSpotConversations' in window) {
      window.HubSpotConversations?.widget?.remove()
    }

    widgetLoaded.current = false
    widgetLoading.current = false
  }, [])

  const initWidget = useCallback(async () => {
    try {
      if (widgetLoaded.current) return
      if (widgetLoading.current) return
      widgetLoading.current = true

      if (user?.email) {
        const { token } = await hubspotIdentify()

        if (widgetLoaded.current) return

        window.hsConversationsSettings = {
          loadImmediately: true,
          identificationEmail: user?.email,
          identificationToken: token,
        }
      }

      // Create HubSpot script
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.id = 'hs-script-loader'
      script.async = true
      script.defer = true
      script.src = '//js.hs-scripts.com/44627413.js'

      document.body.appendChild(script)

      script.addEventListener('load', () => {
        widgetLoaded.current = true
        widgetLoading.current = false
      })
    } catch (err) {
      log.error(err)
    } finally {
      widgetLoading.current = false
    }
  }, [user?.email])

  const resetWidget = useCallback(() => {
    removeWidget()
    initWidget()
  }, [removeWidget, initWidget])

  const shouldShowWidget = useMemo(() => {
    if (location.pathname.includes('/builder/transcribe')) return false
    return true
  }, [location.pathname])

  const hideWidget = useCallback(() => {
    if ('HubSpotConversations' in window) {
      window.HubSpotConversations?.widget?.remove()
    }
  }, [])

  const showWidget = useCallback(() => {
    if ('HubSpotConversations' in window) {
      window.HubSpotConversations?.widget?.load()
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (shouldShowWidget) {
        showWidget()
      } else {
        hideWidget()
      }
    }, 200)

    return () => clearTimeout(timeout)
  }, [shouldShowWidget, showWidget, hideWidget])

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetWidget()
    }, 100)

    return () => clearTimeout(timeout)
  }, [resetWidget])

  return null
}

export default HubSpotChat
