import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import FileUpload from '../forms/FileUpload'
import handleError from '../../util/handleError'
import { LanguageCode } from '@counsel-project/counsel-generation-api'
import ClosableDialog from '../ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import { LanguageSelector } from '@counsel-project/components'

type UploadAudioTranscribeDialogProps = {
  onFinishTranscribe: (session: RPatientSession) => void
  open: boolean
  onClose: () => void
  setLoading: (loading: boolean) => void
  loading: boolean
}

const UploadAudioTranscribeDialog = ({
  onFinishTranscribe,
  open,
  onClose,
  setLoading,
  loading,
}: UploadAudioTranscribeDialogProps) => {
  const [audio, setAudio] = useState<File | null>(null)
  const [language, setLanguage] = useState<LanguageCode>('en')

  const handleUploadFile = useCallback(async () => {
    try {
      if (!audio) return
      if (loading) return
      if (!open) return

      setLoading(true)

      await checkToken()

      const formData = new FormData()
      formData.append('files', audio)

      onClose()

      const { result } = await transcribeRequest.sessions.transcribe.sendAudio({
        token: '',
        formData,
        startedAtString: new Date().toLocaleString(),
        endedAtString: new Date().toLocaleString(),
        language,
        dictation: false,
      })

      onFinishTranscribe(result)
    } catch (err) {
      handleError(err)
    } finally {
      setAudio(null)
    }
  }, [audio, language, loading, open, onFinishTranscribe, onClose, setLoading])

  useEffect(() => {
    if (!audio) return
    const timeout = setTimeout(handleUploadFile, 100)
    return () => clearTimeout(timeout)
  }, [audio, handleUploadFile])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Upload Audio File">
      <DialogContent>
        <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }} textAlign="center">
          Upload your audio file to build a note or document
        </Typography>
        <LanguageSelector
          value={language}
          onChange={setLanguage}
          disabled={loading}
          sx={{ mb: 2 }}
        />
        <FileUpload
          disabled={loading}
          accept="audio/*,video/*,application/octet-stream,application/ogg,application/x-ogg,application/ogg,audio/ogg,audio/wav,audio/mpeg,audio/mp3,audio/x-wav,audio/x-mpeg,audio/x-mp3,audio/x-m4a,audio/x-aac,audio/x-aiff,audio/x-flac"
          title="Upload Audio File"
          file={audio}
          onFileChange={setAudio}
        />
      </DialogContent>
    </ClosableDialog>
  )
}

export default UploadAudioTranscribeDialog
