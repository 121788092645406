import { isApiError } from '@counsel-project/client-utils'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { MuiTelInputInfo } from 'mui-tel-input'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import ProfessionSelector, {
  MedicalProfession,
  MentalHealthProfession,
} from '../components/ProfessionSelector'
import VerifyPhoneDialog from '../components/VerifyPhoneDialog'
import AcquisitionSourceDialog from '../components/layout/AcquisitionSourceDialog'
import PageContainer from '../components/layout/PageContainer'
import { authRequest } from '../util/api/auth-api'
import { url } from '../util/api/url'
import checkToken from '../util/auth/checkToken'
import useRequireAuth from '../util/auth/useRequireAuth'
import useUser from '../util/auth/useUser'
import log from '../util/logging'
import MFASection from './profile/MFASection'
import handleError from '../util/handleError'
import { PhoneTextField } from '@counsel-project/components'
import { RDirectory } from '@counsel-project/counsel-auth-api'
import Divider from '@mui/material/Divider'

const onboardingStageZeroTitle = 'Welcome to Clinical Notes AI'
const onboardingStageZeroText =
  'We help clinicians save up to 80% of their time spent on documentation.'
const onboardingStageZeroDescription =
  'Selecting your profession will help our ability to tailor notes to your individual needs.'

const onboardingStageOneTitle = 'One more thing...'
const onboardingStageOneText = 'Please verify your identity so we can keep your data secure.'
const onboardingStageOneDescription =
  'Enter your phone number and we will send you a verification code.'
const onboardingStageOneDisclaimer = 'Standard messaging rates may apply.'

const Onboarding = () => {
  useTitle('Clinical Notes AI - Onboarding')
  useRequireAuth()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const baaDone = searchParams.get('baa') === 'true'

  const [user, setUser] = useUser()

  const [stagedPhone, setStagedPhone] = useState('')
  const [stagedProfession, setStagedProfession] = useState<
    MentalHealthProfession | MedicalProfession | null
  >(null)
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [downloadedBAA, setDownloadedBAA] = useState(Cookies.get('dl-baa') === 'true')
  const [directories, setDirectories] = useState<RDirectory[]>([])

  const populateDirectories = useCallback(async () => {
    try {
      await checkToken()

      const data = await authRequest.user.directories.list({ token: '' })

      setDirectories(data.results)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateDirectories()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateDirectories])

  const handleSetBAADownloaded = () => {
    setDownloadedBAA(true)
    Cookies.set('dl-baa', 'true', { expires: 365 })
  }

  useEffect(() => {
    if (baaDone) {
      toast.success('BAA Submitted')
      handleSetBAADownloaded()
    }
  }, [baaDone])

  const stage = useMemo(() => {
    if (!user) return -1
    if (!user.profession) return 0
    if (!user.phone) return 1
    if (!downloadedBAA && directories.length === 0) return 2
    if (!user.twoFactorAuth && user.password) return 3
    if (!user.completedTutorial) return 4
    return 5
  }, [user, downloadedBAA, directories])

  useEffect(() => {
    if (stage === 5) {
      navigate('/')
    }
  }, [navigate, stage])

  const populateUser = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const data = await authRequest.user.account.get({ token: '' })
      setUser(data.user)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [setUser])

  useEffect(() => {
    populateUser()
  }, [populateUser])

  const handleSaveProfession = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const data = await authRequest.user.account.update({
        token: '',
        profession: stagedProfession || undefined,
      })

      setUser(data.user)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [setUser, stagedProfession])

  const handleSavePhone = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const data = await authRequest.user.account.registerPhone({
        token: '',
        phone: stagedPhone,
      })

      setUserId(data.userId)
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        navigate('/builder')
      }
    } finally {
      setLoading(false)
    }
  }, [stagedPhone, navigate])

  const handleChangePhone = (value: string, info: MuiTelInputInfo) => {
    if (value.length === 11) {
      if (!info.countryCode) {
        setStagedPhone('+1' + value.substring(1))
        return
      }
    }
    setStagedPhone(value)
  }

  const handleOnVerifyPhone = useCallback(() => {
    toast.success('Phone number verified!')
  }, [])

  const handleOpenBAA = async (e: React.MouseEvent) => {
    try {
      e.preventDefault()
      if (!user) return

      toast.success('BAA Downloading...')
      const response = await fetch(url + '/mailing/baa', {
        method: 'POST',
        body: JSON.stringify({ email: user.email }),
        headers: {
          //File response
          'Content-Type': 'application/pdf',
        },
      })

      const blob = await response.blob()

      // Download the file
      const u = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = u
      a.download = 'Clinical Notes AI Business Associate Agreement Signed.pdf'
      a.click()
    } catch (err) {
      log.error(err)
    }
  }

  const handleBAANext = () => {
    handleSetBAADownloaded()
  }

  const handleTutorialNext = async () => {
    try {
      setLoading(true)

      await authRequest.user.account.update({
        token: '',
        completedTutorial: false,
      })

      navigate('/builder?tutorial=true')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageContainer>
      <Paper elevation={0}>
        <Grid container p={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            {stage === 0 && (
              <>
                <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
                  {onboardingStageZeroTitle}
                </Typography>
                <Typography variant="body1" textAlign="center">
                  {onboardingStageZeroText}
                </Typography>
                <Typography variant="body1" textAlign="center" sx={{ mt: 1 }}>
                  {onboardingStageZeroDescription}
                </Typography>
                <ProfessionSelector
                  sx={{ mt: 2 }}
                  value={stagedProfession}
                  onChange={setStagedProfession}
                  disabled={loading}
                />
                <LoadingButton
                  sx={{ mt: 2 }}
                  fullWidth
                  variant="text"
                  loading={loading}
                  disabled={!stagedProfession}
                  onClick={handleSaveProfession}
                >
                  Save
                </LoadingButton>
              </>
            )}
            {stage === 1 && (
              <>
                <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
                  {onboardingStageOneTitle}
                </Typography>
                <Typography variant="body1" textAlign="center">
                  {onboardingStageOneText}
                </Typography>
                <Typography variant="body1" textAlign="center" sx={{ mt: 1 }}>
                  {onboardingStageOneDescription}
                </Typography>
                <PhoneTextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Phone Number"
                  value={stagedPhone}
                  onChange={handleChangePhone}
                  aria-label="phone number"
                  defaultCountry="US"
                />
                <LoadingButton
                  sx={{ mt: 2 }}
                  fullWidth
                  variant="text"
                  loading={loading}
                  onClick={handleSavePhone}
                  disabled={!stagedPhone}
                  aria-label="save phone number"
                >
                  Save
                </LoadingButton>
                <Typography
                  variant="body2"
                  textAlign="center"
                  color="text.secondary"
                  sx={{ mt: 2 }}
                >
                  {onboardingStageOneDisclaimer}
                </Typography>
              </>
            )}
            {stage === 2 && (
              <>
                <Grid container justifyContent="center">
                  <Grid item textAlign="center" xs={12}>
                    <Typography textAlign="center" variant="h5">
                      HIPAA Compliance
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      Feel free to download our BAA and submit a signed copy via the button below.
                    </Typography>
                    <Button sx={{ mt: 2 }} variant="contained" onClick={handleOpenBAA}>
                      Download BAA
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{ mt: 2 }}
                      href="https://share.hsforms.com/1lDe9NIgPTcmQPQzD2HjAMAqkiqd"
                    >
                      Submit BAA
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}
                  >
                    <Button variant="contained" onClick={handleBAANext}>
                      Skip
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 2,
                      }}
                      textAlign="center"
                    >
                      Press Skip if you have already submitted your BAA, or your organization
                      already has a BAA on file.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {stage === 3 && (
              <>
                <Grid container justifyContent="center">
                  <Grid item>
                    <MFASection />
                  </Grid>
                </Grid>
              </>
            )}
            {stage === 4 && (
              <>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
                      Onboarding Tour
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleTutorialNext} disabled={loading}>
                      Take Platform Tour
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <VerifyPhoneDialog
        open={Boolean(userId)}
        userId={userId}
        onClose={() => setUserId('')}
        onVerify={handleOnVerifyPhone}
      />
      <AcquisitionSourceDialog />
    </PageContainer>
  )
}

export default Onboarding
