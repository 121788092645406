import { capitalize } from '@counsel-project/client-utils'
import BackIcon from '@mui/icons-material/NavigateBefore'
import NextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Tour, { ReactourStep } from 'reactour'
import { usePatientNomenclature } from '../util'
import { authRequest } from '../util/api/auth-api'
import useUser from '../util/auth/useUser'
import handleError from '../util/handleError'
import theme from '../util/theme'
import { mentalHealthCategories } from './ProfessionSelector'
import useLayouts from '../util/auth/useLayouts'
import { transcribeRequest } from '../util/api/transcribe-api'
import checkToken from '../util/auth/checkToken'

export const medicalScript = `Hi, how are you doing today?
I'm not doing well, I'm having a lot of pain in my back.
I'm sorry to hear that. Let's prescribe you some pain medication. Take this twice a day for the next week.`

export const therapyScript = `Hi, how are you doing today?
I'm not doing very well, my worrying thoughts are getting worse and worse and I can't focus. It's affecting my work and school. I'm not sure what to do.
I'm sorry to hear that, that must be scary for you. We're going to go through a couple of exercises today that will help you relax and I'll send you some resources after our session today. Lets meet every week for the next month and we'll measure our progress over time.`

export const isMedical = (profession: string) => {
  return !(profession in mentalHealthCategories)
}

type TimedLinearProgressBarProps = {
  duration: number
  onEnd?: () => void
}

const TimedLinearProgressBar = ({ duration, onEnd }: TimedLinearProgressBarProps) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 1)
    }, duration / 100)

    return () => clearInterval(interval)
  }, [duration])

  useEffect(() => {
    if (progress >= 100) {
      onEnd?.()
    }
  }, [progress, onEnd])

  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{ position: 'relative', top: 20, left: 0, borderRadius: 10 }}
    />
  )
}

type StepNavbarProps = {
  totalSteps: number
  currentStep: number
  onBack?: (i?: number) => void
  onNext?: (i?: number) => void
}

const StepNavbar = ({ totalSteps, currentStep, onBack, onNext }: StepNavbarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 1,
      }}
    >
      <Button
        onClick={() => onBack?.()}
        disabled={!onBack}
        sx={{
          mr: 1,
          opacity: !onBack ? 0.5 : 1,
        }}
        startIcon={<BackIcon />}
        color="primary"
      >
        Back
      </Button>
      <Button
        onClick={() => onNext?.()}
        disabled={!onNext}
        sx={{
          ml: 1,
          opacity: !onNext ? 0.5 : 1,
        }}
        endIcon={<NextIcon />}
        color="primary"
      >
        Next
      </Button>
    </Box>
  )
}

const handleClickMobileNavbar = () => {
  const node = document.getElementById('mobile-nav-menu-button')
  if (node) {
    const isOpen = node.attributes.getNamedItem('aria-expanded')?.value === 'true'
    if (!isOpen) {
      node.focus()
      node.click()
    }
  }
}

const handleCloseMobileNavbar = () => {
  const node = document.getElementById('mobile-nav-menu-button')
  if (node) {
    const isOpen = node.attributes.getNamedItem('aria-expanded')?.value === 'true'
    if (isOpen) {
      node.focus()
      node.click()
      const menuNode = document.getElementById('mobile-nav-menu')
      menuNode?.style.setProperty('display', 'none')
    }
  }
}

type StepProps = {
  totalSteps: number
  currentStep: number
  onNext: (i?: number) => void
  onBack: (i?: number) => void
  onClose: () => void
}

type Step = {
  Component: React.FC<StepProps>
  position: ReactourStep['position']
  stepInteraction: ReactourStep['stepInteraction']
  selector?: string
  highlightedSelectors?: string[]
  mutationObservables?: string[]
  mutationObserverOptions?: MutationObserverInit
}

const getSteps: (isMobile: boolean, isMedicalProfession: boolean) => Step[] = (
  isMobile,
  isMedicalProfession
) => [
  {
    Component: ({ totalSteps, currentStep, onNext }) => {
      const navigate = useNavigate()

      useEffect(() => {
        navigate('/?tutorial=true')
      }, [navigate])

      useEffect(() => {
        // Click the mobile nav menu button if it exists
        if (isMobile) {
          handleClickMobileNavbar()
        }
      }, [])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Box sx={{ mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src="/logo-alt.svg" alt="logo" style={{ height: 100 }} />
            </Box>
            <Typography textAlign="center">
              Welcome to the Clinical Notes AI tour.
              <br /> We’re going to give you a brief walk through of the product and help you create
              your first note!
            </Typography>
          </Box>
          <StepNavbar onNext={onNext} totalSteps={totalSteps} currentStep={currentStep} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: false,
  },
  {
    Component: ({ totalSteps, onNext, onBack }: StepProps) => {
      useEffect(() => {
        if (isMobile) {
          handleClickMobileNavbar()
        }
      }, [])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>Use this menu to navigate around the app.</Typography>
          </Box>
          <StepNavbar onNext={onNext} onBack={onBack} totalSteps={totalSteps} currentStep={1} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: false,
    selector: isMobile ? '#mobile-nav-menu' : '#main-toolbar',
  },
  {
    Component: ({ totalSteps, onNext, onBack }: StepProps) => {
      useEffect(() => {
        if (isMobile) {
          handleClickMobileNavbar()
        }
      }, [])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>You can use AI to generate documents here.</Typography>
          </Box>
          <StepNavbar onNext={onNext} onBack={onBack} totalSteps={totalSteps} currentStep={2} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: false,
    selector: isMobile ? '#mobile-nav-documents' : '#nav-documents',
  },
  {
    Component: ({ totalSteps, onNext, onBack }: StepProps) => {
      useEffect(() => {
        if (isMobile) {
          handleClickMobileNavbar()
        }
      }, [])

      const patient = usePatientNomenclature()

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>
              Your {patient}s, saved notes, and previous sessions can be found here.
            </Typography>
          </Box>
          <StepNavbar onNext={onNext} onBack={onBack} totalSteps={totalSteps} currentStep={2} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: false,
    selector: isMobile ? '#mobile-nav-sessions' : '#nav-sessions',
  },
  {
    Component: ({ totalSteps, onNext, onBack }: StepProps) => {
      const location = useLocation()
      const [layouts] = useLayouts()

      useEffect(() => {
        const interval = setInterval(() => {
          if (location.pathname === '/builder' && layouts.length > 0) {
            handleCloseMobileNavbar()
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [location, onNext, layouts])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>Lets start an example session. Click the Documentation button.</Typography>
          </Box>
          <StepNavbar onBack={onBack} totalSteps={totalSteps} currentStep={4} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: isMobile ? '#mobile-nav-documents' : '#nav-documents',
  },
  {
    Component: ({ totalSteps, onNext, onBack }: StepProps) => {
      const [searchParams, setSearchParams] = useSearchParams()
      const navigate = useNavigate()
      const [layouts, setLayouts] = useLayouts()

      useEffect(() => {
        setSearchParams((prev) => {
          prev.set('tutorial', 'true')
          prev.set('showAll', 'true')
          return prev
        })

        const interval = setInterval(() => {
          if (document.querySelector('#live-session-button')) {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [searchParams, onNext, setSearchParams])

      const handleBack = useCallback(() => {
        if (isMobile) {
          handleClickMobileNavbar()
        }
        navigate('/?tutorial=true')

        onBack()
      }, [navigate, onBack])

      const handlePopulateMissingLayouts = useCallback(async () => {
        try {
          await checkToken()

          if (!isMedicalProfession) {
            const { results } = await transcribeRequest.layouts.list({
              token: '',
              search: {
                and: [{ identifier: 'general' }],
              },
              limit: 1,
            })

            if (!results[0]) return

            setLayouts([
              ...layouts.filter((layout) => layout?.identifier !== 'general'),
              results[0],
            ])
          } else {
            const { results } = await transcribeRequest.layouts.list({
              token: '',
              search: {
                and: [{ identifier: 'medical' }],
              },
              limit: 1,
            })

            if (!results[0]) return

            setLayouts([
              ...layouts.filter((layout) => layout?.identifier !== 'medical'),
              results[0],
            ])
          }
        } catch (err) {
          handleError(err)
        }
      }, [layouts, setLayouts])

      useEffect(() => {
        if (
          (!isMedicalProfession && !layouts.find((layout) => layout?.identifier === 'general')) ||
          (isMedicalProfession && !layouts.find((layout) => layout?.identifier === 'medical'))
        ) {
          // Since the general layout is not available, we need to create it
          handlePopulateMissingLayouts()
        }
      }, [layouts, handlePopulateMissingLayouts])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>Select the type of note you would like to generate.</Typography>
          </Box>
          <StepNavbar onBack={handleBack} totalSteps={totalSteps} currentStep={5} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: isMedicalProfession ? '#layout-option-medical' : '#layout-option-general',
  },
  {
    Component: ({ totalSteps, onNext, onBack }: StepProps) => {
      const [searchParams, setSearchParams] = useSearchParams()

      useEffect(() => {
        const interval = setInterval(() => {
          if (searchParams.get('consentOpen') === 'true') {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [searchParams, onNext])

      const handleBack = () => {
        setSearchParams((prev) => {
          prev.delete('generateOpen')
          prev.delete('layout')
          return prev
        })

        onBack()
      }

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>
              Lets have a mock live session. Click the "Live Session" button here.
            </Typography>
          </Box>
          <StepNavbar onBack={handleBack} totalSteps={totalSteps} currentStep={6} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: '#live-session-button',
  },
  {
    Component: ({ currentStep, totalSteps, onNext, onBack }: StepProps) => {
      useEffect(() => {
        const interval = setInterval(() => {
          const seeConsentButton = document.getElementById('see-consent-script-button')
          if (!seeConsentButton) return
          if (seeConsentButton.attributes.getNamedItem('aria-expanded')?.value !== 'true') return
          onNext()
        }, 200)
        return () => clearInterval(interval)
      }, [onNext])

      const handleBack = () => {
        const closeConsentButton = document.getElementById('deny-consent-button')
        if (closeConsentButton) {
          closeConsentButton.click()
        }

        onBack()
      }

      const patient = usePatientNomenclature()

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>
              We’ve built a consent script for you to say to your {patient} prior to starting the
              session, once they agree verbally, click the {patient} consented button to start the
              session.
            </Typography>
          </Box>
          <StepNavbar onBack={handleBack} totalSteps={totalSteps} currentStep={currentStep} />
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: '#see-consent-script-button',
    mutationObservables: ['#see-consent-script-button'],
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      const [searchParams] = useSearchParams()

      const handleBack = () => {
        const closeConsentButton = document.getElementById('see-consent-script-button')
        if (closeConsentButton) {
          closeConsentButton.click()
        }

        onBack()
      }

      useEffect(() => {
        const interval = setInterval(() => {
          if (searchParams.get('startedAtString')) {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [searchParams, onNext])

      const patient = usePatientNomenclature()

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>
              Confirm your {patient} has your consent to start the session by pressing "
              {capitalize(patient)} Consented"
            </Typography>
            <StepNavbar onBack={handleBack} totalSteps={totalSteps} currentStep={currentStep} />
          </Box>
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: '#confirm-consent-button',
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      const handleBack = () => {
        const closeConsentButton = document.getElementById('close-start-session-button')
        if (closeConsentButton) {
          closeConsentButton.click()
        }

        setTimeout(() => {
          onBack(4)
        }, 500)
      }

      useEffect(() => {
        const interval = setInterval(() => {
          const element = document.getElementById('mic-finish-button')
          if (element) {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [onNext])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography>Start the session by clicking the "Start Session" button.</Typography>
            <StepNavbar onBack={handleBack} totalSteps={totalSteps} currentStep={currentStep} />
          </Box>
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: '#confirm-start-button',
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      const script = isMedicalProfession ? medicalScript : therapyScript

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Lets emulate a quick session. Read the following out loud:
            </Typography>
            {script.split('\n').map((line) => (
              <Typography variant="body1" fontStyle="italic" sx={{ mb: 1 }}>
                {line}
              </Typography>
            ))}
          </Box>
          <StepNavbar onNext={onNext} totalSteps={totalSteps} currentStep={currentStep} />
        </>
      )
    },
    position: 'top',
    stepInteraction: false,
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      const handleBack = () => {
        onBack()
      }

      useEffect(() => {
        const interval = setInterval(() => {
          const element = document.getElementById('mic-finish-button')
          if (!element) {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [onNext])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              When you are done, press the "Finish" button below.
            </Typography>
            <StepNavbar onBack={handleBack} totalSteps={totalSteps} currentStep={currentStep} />
          </Box>
        </>
      )
    },
    position: 'top',
    stepInteraction: true,
    selector: '#mic-finish-button',
  },
  {
    Component: ({ totalSteps, onNext, onBack }: StepProps) => {
      const location = useLocation()

      useEffect(() => {
        const interval = setInterval(() => {
          if (
            location.pathname.includes('/builder/layout/') &&
            document.getElementById('layout-option-category-0')
          ) {
            setTimeout(() => {
              onNext()
            }, 400)
          }
        }, 800)
        return () => clearInterval(interval)
      }, [location.pathname, onNext])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">Your session is being analyzed...</Typography>
          </Box>
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: '#transcribe-loading-dialog',
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">
              Select what kind of note you would like to generate.
            </Typography>
            <StepNavbar onNext={onNext} totalSteps={totalSteps} currentStep={currentStep} />
          </Box>
        </>
      )
    },
    position: 'top',
    stepInteraction: true,
    selector: '#layout-option-category-0',
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">
              Select any additional information you would like to include.
            </Typography>
            <StepNavbar
              onBack={onBack}
              onNext={onNext}
              totalSteps={totalSteps}
              currentStep={currentStep}
            />
          </Box>
        </>
      )
    },
    position: 'top',
    stepInteraction: true,
    selector: '#layout-option-category-1',
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">
              Select the formatting that you would like to apply to the note.
            </Typography>
            <StepNavbar
              onBack={onBack}
              onNext={onNext}
              totalSteps={totalSteps}
              currentStep={currentStep}
            />
          </Box>
        </>
      )
    },
    position: 'top',
    stepInteraction: true,
    selector: '#layout-option-category-2',
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">
              Here, you can choose how long you would like your note to be.
            </Typography>
            <StepNavbar
              onBack={onBack}
              onNext={onNext}
              totalSteps={totalSteps}
              currentStep={currentStep}
            />
          </Box>
        </>
      )
    },
    position: 'top',
    stepInteraction: true,
    selector: '#layout-option-category-3',
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      const location = useLocation()

      useEffect(() => {
        const interval = setInterval(() => {
          if (location.pathname.includes('/builder/generating')) {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [location.pathname, onNext])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">
              Click the Generate button to start building your note.
            </Typography>
            <StepNavbar onBack={onBack} totalSteps={totalSteps} currentStep={currentStep} />
          </Box>
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: '#generate-notes-button',
  },
  {
    Component: ({ totalSteps, onNext, onBack, onClose }: StepProps) => {
      const location = useLocation()

      useEffect(() => {
        const interval = setInterval(() => {
          const element = document.querySelector('.generating-success')
          if (element) {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [location.pathname, onNext, onClose])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">Your note will be generated in a few moments...</Typography>
          </Box>
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
  },
  {
    Component: ({ totalSteps, currentStep, onNext, onBack }: StepProps) => {
      const location = useLocation()

      useEffect(() => {
        const interval = setInterval(() => {
          if (location.pathname.includes('/sessions/')) {
            onNext()
          }
        }, 200)
        return () => clearInterval(interval)
      }, [location.pathname, onNext])

      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">Click the View button to see your note.</Typography>
            <StepNavbar onBack={onBack} totalSteps={totalSteps} currentStep={currentStep} />
          </Box>
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
    selector: '.view-note-button',
  },
  {
    Component: ({ totalSteps, onBack, onClose }: StepProps) => {
      return (
        <>
          <Box sx={{ pr: 1 }}>
            <Typography variant="body1">Your notes are ready!</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              They're separated into sections for easy export. You can also email or copy everything
              with one button. Congrats on creating your first note with Clinical Notes AI!
            </Typography>
            <Button sx={{ mt: 1 }} onClick={onClose}>
              Finish
            </Button>
            <TimedLinearProgressBar duration={8000} onEnd={onClose} />
          </Box>
        </>
      )
    },
    position: 'bottom',
    stepInteraction: true,
  },
]

const Tutorial = () => {
  const [user, setUser] = useUser()

  const [searchParams, setSearchParams] = useSearchParams()

  const [tourOpen, setTourOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const location = useLocation()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isMedicalProfession = useMemo(() => {
    const profession = user?.profession || 'therapist'
    return isMedical(profession)
  }, [user?.profession])

  const handleCloseTour = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('tutorial')
      return prev
    })
    setTourOpen(false)
    setCurrentStep(0)
  }, [setSearchParams])

  const closeTour = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.currentTarget.attributes.getNamedItem('aria-label')?.nodeValue !== 'Close') {
        return
      }
      handleCloseTour()
    },
    [handleCloseTour]
  )

  const openTour = useCallback(() => {
    setTourOpen(true)
    setSearchParams((prev) => {
      prev.set('tutorial', 'true')
      return prev
    })
  }, [setSearchParams])

  useEffect(() => {
    if (searchParams.get('tutorial') !== 'true') {
      return
    }

    if (user?.completedTutorial) {
      return
    }

    const timeout = setTimeout(() => {
      openTour()
    }, 400)
    return () => {
      clearTimeout(timeout)
    }
  }, [openTour, searchParams, user?.completedTutorial])

  const handleFinish = async () => {
    try {
      const { user } = await authRequest.user.account.update({
        token: '',
        completedTutorial: true,
      })

      setUser(user)

      setCurrentStep(0)
    } catch (err) {
      handleError(err)
    } finally {
      setCurrentStep(0)
    }
  }

  const handleNext = useCallback((i = 1) => {
    setCurrentStep((prev) => prev + i)
  }, [])

  const handleBack = useCallback((i = 1) => {
    setCurrentStep((prev) => prev - i)
  }, [])

  useEffect(() => {
    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    let keys = { 37: 1, 38: 1, 39: 1, 40: 1 }

    function preventDefault(e: Event) {
      e.preventDefault()
    }

    function preventDefaultForScrollKeys(e: Event) {
      /** @ts-ignore */
      if (keys[e.keyCode]) {
        preventDefault(e)
        return false
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false
    try {
      /** @ts-ignore */
      window.addEventListener(
        'test',
        null,
        Object.defineProperty({}, 'passive', {
          // eslint-disable-next-line getter-return
          get: function () {
            supportsPassive = true
          },
        })
      )
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

    // call this to Disable
    function disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
      window.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
      window.addEventListener('keydown', preventDefaultForScrollKeys, false)
    }

    function enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault)
      window.removeEventListener(wheelEvent, preventDefault)
      window.removeEventListener('touchmove', preventDefault)
      window.removeEventListener('keydown', preventDefaultForScrollKeys)
    }

    if (!tourOpen) return
    if (searchParams.get('recordingIndex')) return
    if (location.pathname.includes('/sessions/')) return

    disableScroll()
    return () => {
      enableScroll()
    }
  }, [tourOpen, searchParams, location.pathname])

  const steps: ReactourStep[] = useMemo(() => {
    const totalSteps = getSteps(isMobile, isMedicalProfession)
    const tourSteps: ReactourStep[] = totalSteps.map((step, i) => {
      const { Component, ...rest } = step
      return {
        content: (
          <Component
            key={'step' + i}
            currentStep={i + 1}
            totalSteps={totalSteps.length}
            {...rest}
            onNext={handleNext}
            onBack={handleBack}
            onClose={handleCloseTour}
          />
        ),
        ...rest,
      }
    })
    return tourSteps
  }, [isMobile, handleBack, handleNext, handleCloseTour, isMedicalProfession])

  if (!tourOpen) return null

  return (
    <Tour
      steps={steps}
      isOpen={tourOpen}
      onRequestClose={closeTour}
      rounded={8}
      goToStep={currentStep}
      nextStep={() => setCurrentStep((prev) => prev + 1)}
      prevStep={() => setCurrentStep((prev) => prev - 1)}
      accentColor={theme.palette.secondary.main}
      showNumber={false}
      disableFocusLock
      disableDotsNavigation={true}
      disableKeyboardNavigation={true}
      showNavigation={false}
      showButtons={false}
      lastStepNextButton={
        <Button variant="contained" sx={{ p: 0.5, px: 1 }} onClick={handleFinish}>
          Finish
        </Button>
      }
      updateDelay={100}
    />
  )
}

export default Tutorial
